@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400 !important;600 !important;700&display=swap");
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
:root {
  --header-height: 3rem !important;
  --font-semi: 600 !important;
}
:root {
  --first-color: #4070f4 !important;
  --second-color: #0e2431 !important;
}
:root {
  --body-font: "Poppins", sans-serif !important;
  --big-font-size: 2rem !important;
  --h2-font-size: 1.25rem !important;
  --normal-font-size: 0.938rem !important;
}
@media screen and (min-width: 768px) {
  :root {
    --big-font-size: 3.5rem !important;
    --h2-font-size: 2rem !important;
    --normal-font-size: 1rem !important;
  }
}
:root {
  --mb-1: 0.5rem !important;
  --mb-2: 1rem !important;
  --mb-3: 1.5rem !important;
  --mb-4: 2rem !important;
  --mb-5: 2.5rem !important;
  --mb-6: 3rem !important;
}
:root {
  --z-back: -10 !important;
  --z-normal: 1 !important;
  --z-tooltip: 10 !important;
  --z-fixed: 100 !important;
}
*,
::after,
::before {
  box-sizing: border-box !important;
}
html {
  scroll-behavior: smooth !important;
}
body {
  margin: var(--header-height) 0 0 0 !important;
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size) !important;
  color: var(--second-color) !important;
}
h1,
h2,
p {
  margin: 0 !important;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}
a {
  text-decoration: none !important;
}
img {
  max-width: 100% !important;
  height: auto !important;
  display: block !important;
}
.section-title {
  position: relative !important;
  font-size: var(--h2-font-size) !important;
  color: var(--first-color) !important;
  margin-top: var(--mb-2) !important;
  margin-bottom: var(--mb-4) !important;
  text-align: center !important;
}
.section-title::after {
  position: absolute !important;
  content: "" !important;
  width: 64px !important;
  height: 0.18rem !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  top: 2rem !important;
  background-color: var(--first-color) !important;
}
.section {
  padding-top: 3rem !important;
  padding-bottom: 2rem !important;
}
.bd-grid {
  max-width: 1024px !important;
  display: grid !important;
  grid-template-columns: 100% !important;
  grid-column-gap: 2rem !important;
  width: calc(100% - 2rem) !important;
  margin-left: var(--mb-2) !important;
  margin-right: var(--mb-2) !important;
}
.l-header {
  width: 100% !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: var(--z-fixed) !important;
  background-color: #fff !important;
  box-shadow: 0 1px 4px rgba(146, 161, 176, 0.15) !important;
}
.nav {
  height: var(--header-height) !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-weight: var(--font-semi) !important;
}
@media screen and (max-width: 768px) {
  .nav__menu {
    position: fixed !important;
    top: var(--header-height) !important;
    right: -100% !important;
    width: 80% !important;
    height: 100% !important;
    padding: 2rem !important;
    background-color: var(--second-color) !important;
    transition: 0.5s !important;
  }
}
.nav__item {
  margin-bottom: var(--mb-4) !important;
}
.nav__link {
  position: relative !important;
  color: #fff !important;
}
.nav__link:hover {
  position: relative !important;
}
.nav__link:hover::after {
  position: absolute !important;
  content: "" !important;
  width: 100% !important;
  height: 0.18rem !important;
  left: 0 !important;
  top: 2rem !important;
  background-color: var(--first-color) !important;
}
.nav__logo {
  color: var(--second-color) !important;
}
.nav__toggle {
  color: var(--second-color) !important;
  font-size: 1.5rem !important;
  cursor: pointer !important;
}
.active::after {
  position: absolute !important;
  content: "" !important;
  width: 100% !important;
  height: 0.18rem !important;
  left: 0 !important;
  top: 2rem !important;
  background-color: var(--first-color) !important;
}
.show {
  right: 0 !important;
}
.home {
  height: calc(100vh - 3rem) !important;
  row-gap: 1rem !important;
}
.home__data {
  align-self: center !important;
}
.home__title {
  font-size: var(--big-font-size) !important;
  margin-bottom: var(--mb-5) !important;
}
.home__title-color {
  color: var(--first-color) !important;
}
.home__social {
  display: flex !important;
  flex-direction: column !important;
}
.home__social-icon {
  width: max-content !important;
  margin-bottom: var(--mb-2) !important;
  font-size: 1.5rem !important;
  color: var(--second-color) !important;
}
.home__social-icon:hover {
  color: var(--first-color) !important;
}
.home__img {
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 295px !important;
}
.button {
  display: inline-block !important;
  background-color: var(--first-color) !important;
  color: #fff !important;
  padding: 0.75rem 2.5rem !important;
  font-weight: var(--font-semi) !important;
  border-radius: 0.5rem !important;
}
.button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15) !important;
}
.about__container {
  row-gap: 2rem !important;
  text-align: center !important;
}
.about__subtitle {
  margin-bottom: var(--mb-2) !important;
}
.about__text {
  margin-top: var(--mb-1) !important;
}
.about__img {
  justify-self: center !important;
}
.about__img img {
  width: 200px !important;
  border-radius: 0.5rem !important;
}
.skills__container {
  row-gap: 2rem !important;
  text-align: center !important;
}
.skills__subtitle {
  margin-bottom: var(--mb-2) !important;
}
.skills__text {
  margin-bottom: var(--mb-4) !important;
}
.skills__data {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  position: relative !important;
  font-weight: var(--font-semi) !important;
  padding: 0.5rem 1rem !important;
  margin-bottom: var(--mb-4) !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 4px 25px rgba(14, 36, 49, 0.15) !important;
}
.skills__icon {
  font-size: 2rem !important;
  margin-right: var(--mb-2) !important;
  color: var(--first-color) !important;
}
.skills__names {
  display: flex !important;
  align-items: center !important;
}
.skills__bar {
  position: absolute !important;
  left: 0 !important;
  bottom: 0 !important;
  background-color: var(--first-color) !important;
  height: 0.25rem !important;
  border-radius: 0.5rem !important;
  z-index: var(--z-back) !important;
}
.skills__html {
  width: 95% !important;
}
.skills__css {
  width: 85% !important;
}
.skills__js {
  width: 65% !important;
}
.skills__ux {
  width: 85% !important;
}
.skills__img {
  border-radius: 0.5rem !important;
}
.work__container {
  row-gap: 2rem !important;
}
.work__img {
  box-shadow: 0 4px 25px rgba(14, 36, 49, 0.15) !important;
  border-radius: 0.5rem !important;
  overflow: hidden !important;
}
.work__img img {
  transition: 1s !important;
  cursor: pointer !important;
}
.work__img img:hover {
  transform: scale(1.1) !important;
}
.contact__input {
  width: 100% !important;
  font-size: var(--normal-font-size) !important;
  font-weight: var(--font-semi) !important;
  padding: 1rem !important;
  border-radius: 0.5rem !important;
  border: 1.5px solid var(--second-color);
  outline: 0 !important;
  margin-bottom: var(--mb-4) !important;
}
.contact__button {
  display: block !important;
  border: none !important;
  outline: 0 !important;
  font-size: var(--normal-font-size) !important;
  cursor: pointer !important;
  margin-left: auto !important;
}
.footer {
  background-color: var(--second-color) !important;
  color: #fff !important;
  text-align: center !important;
  font-weight: var(--font-semi) !important;
  padding: 2rem 0 !important;
}
.footer__title {
  font-size: 2rem !important;
  margin-bottom: var(--mb-4) !important;
}
.footer__social {
  margin-bottom: var(--mb-4) !important;
}
.footer__icon {
  font-size: 1.5rem !important;
  color: #fff !important;
  margin: 0 var(--mb-2);
}
blockquote {
  font-size: 1.4em !important;
  font-family: Open Sans !important;
  font-style: italic !important;
  padding: 1.2em 30px 1.2em 75px !important;
  border-left: 8px solid var(--first-color) !important;
  line-height: 1.6 !important;
  position: relative !important;
}
blockquote::before {
  font-family: Arial !important;
  content: "\201C" !important;
  color: var(--first-color) !important;
  font-size: 4em !important;
  position: absolute !important;
  left: 10px !important;
  top: -10px !important;
}
blockquote::after {
  content: "" !important;
}
blockquote span {
  display: block !important;
  color: #333 !important;
  font-style: normal !important;
  font-weight: 700 !important;
  margin-top: 1em !important;
}
@media screen and (min-width: 768px) {
  body {
    margin: 0 !important;
  }
  .section {
    padding-top: 4rem !important;
    padding-bottom: 3rem !important;
  }
  .section-title {
    margin-bottom: var(--mb-6) !important;
  }
  .section-title::after {
    width: 80px !important;
    top: 3rem !important;
  }
  .nav {
    height: calc(var(--header-height) + 1rem) !important;
  }
  .nav__list {
    display: flex !important;
    padding-top: 0 !important;
  }
  .nav__item {
    margin-left: var(--mb-6) !important;
    margin-bottom: 0 !important;
  }
  .nav__toggle {
    display: none !important;
  }
  .nav__link {
    color: var(--second-color) !important;
  }
  .home {
    height: 100vh !important;
  }
  .home__data {
    align-self: flex-end !important;
  }
  .home__social {
    padding-top: 0 !important;
    padding-bottom: 2.5rem !important;
    flex-direction: row !important;
    align-self: flex-end !important;
  }
  .home__social-icon {
    margin-bottom: 0 !important;
    margin-right: var(--mb-4) !important;
  }
  .home__img {
    width: 457px !important;
    bottom: 15% !important;
  }
  .about__container,
  .skills__container {
    grid-template-columns: repeat(2, 1fr) !important;
    align-items: center !important;
    text-align: initial !important;
  }
  .about__img img {
    width: 300px !important;
  }
  .work__container {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-template-rows: repeat(1, 1fr) !important;
    column-gap: 2rem !important;
  }
  .contact__form {
    width: 360px !important;
  }
  .contact__container {
    justify-items: center !important;
  }
}
@media screen and (min-width: 1024px) {
  .bd-grid {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .home__img {
    right: 10% !important;
  }
}
